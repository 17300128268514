export default defineNuxtRouteMiddleware(async (to) => {
  const {
    isAuthenticated,
    isStudentSelected,
    goToUserDefaultPage,
    hasApplicationInOtherModule,
    hasApplication,
    studentList,
  } = await useAuth();
  if (!isAuthenticated.value && to.path !== "/unauthorized") {
    const config = useRuntimeConfig();
    clearNuxtData((key) => key.startsWith("/api/user"));
    reloadNuxtApp({
      path: `${config.app.baseURL}/unauthorized`,
      force: true,
      persistState: false,
    });
    return;
  }
  if (
    (!isStudentSelected.value ||
      studentList.value?.length === 0 ||
      (hasApplicationInOtherModule.value && !hasApplication.value)) &&
    isAuthenticated.value &&
    to.path !== "/user/loggedIn"
  ) {
    return navigateTo("/user/loggedIn");
  } else if (
    isStudentSelected.value &&
    !hasApplicationInOtherModule.value &&
    to.path === "/user/loggedIn"
  ) {
    return goToUserDefaultPage();
  }
});
